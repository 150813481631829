<template>
  <div class="organization-members-con">
    <div class="page-header">
      <div class="page-title">
        {{$t('enterprise.memberManagement.title')}}
      </div>
      <div class="page-action">
        <a-button type="primary" @click="isInviteModalShow = true">{{$t('enterprise.memberManagement.invitePeople')}}</a-button>
      </div>
    </div>
    <div class="divide-line"></div>
    <!-- <div class="table-filter">
      <a-input v-model:value="searchValue" :placeholder="$t('enterprise.memberManagement.enterName')" style="flex:3" >
        <template #suffix>
          <SearchOutlined />
        </template>
      </a-input>
      <div class="filter-item">
        <div class="filter-title">{{$t('enterprise.memberManagement.project')}}</div>
        <a-select
          class="filter-main"
          v-model:value="projectValue"
          placeholder="全部"
          allowClear
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in projectOptions"
            :key="index">{{item.label}}</a-select-option>
        </a-select>
      </div>
      <div class="filter-item">
        <div class="filter-title">{{$t('enterprise.memberManagement.state')}}</div>
        <a-select
          class="filter-main"
          v-model:value="stateValue"
          placeholder="全部"
          allowClear
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in stateOptions"
            :key="index">{{item.label}}</a-select-option>
        </a-select>
      </div>
    </div> -->
    <div class="page-content">
      <a-table
        :data-source="tableData"
        :columns="tableColumns"
        :pagination="false"
        sticky>
        <template #headerCell="{ title }">
          {{$t(`enterprise.memberManagement.${title}`)}}
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'action'">
            <FormOutlined class="action-icon edit-icon" />
            <DeleteOutlined class="action-icon delete-icon" />
          </template>
          <template v-if="column.dataIndex === 'state'">
            <a-tag :color="getTag(record?.disabled).color">
              {{$t(`enterprise.memberManagement.${getTag(record?.disabled).str}`)}}
            </a-tag>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <InviteModal
    v-model:visible="isInviteModalShow"
    @emailSubmit="emailSubmit"
    @linkSubmit="linkSubmit"
    @currentProject="currentProject"/>
  <InviteResultModal v-model:visible="isInviteResultModalShow" />
  <InviteLinkModal
    v-model:visible="isInviteLinkModalShow"
    :value="linkUrl"
    :inviter="$store.state?.user?.userInfo?.nickName"
    :projectName="projectName"/>

</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/services/api'
import InviteModal from '@/components/admin/invite/InviteModal'
import InviteResultModal from '@/components/admin/invite/InviteResultModal'
import InviteLinkModal from '@/components/admin/invite/InviteLinkModal'
import {
  // SearchOutlined,
  FormOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'MemberManagement',
  components: {
    // SearchOutlined,
    FormOutlined,
    DeleteOutlined,
    InviteModal,
    InviteResultModal,
    InviteLinkModal
  },
  props: {},
  setup (props, context) {
    const route = useRoute()

    const state = reactive({
      searchValue: null,
      projectValue: null,
      projectOptions: [

      ],
      stateValue: null,
      stateOptions: [],
      tableData: [],
      tableColumns: [
        // {
        //   title: 'userId',
        //   dataIndex: 'userId',
        //   key: 'userId'
        // },
        {
          title: 'username',
          dataIndex: 'nickName',
          key: 'nickName'
        },
        // {
        //   title: 'project',
        //   dataIndex: 'project',
        //   key: 'project'
        // },
        {
          title: 'email',
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: 'role',
          dataIndex: 'role',
          key: 'role'
        },
        {
          title: 'state',
          dataIndex: 'state',
          key: 'state'
        }
        // {
        //   title: 'action',
        //   dataIndex: 'action'
        // }
      ],
      isInviteModalShow: false,
      isInviteResultModalShow: false,
      isInviteLinkModalShow: false,
      linkUrl: '',
      projectName: ''
    })
    onMounted(async () => {
      getOrgMemberList()
    })

    const emailSubmit = async (formData) => {
      const orgId = route.params?.orgId
      if (orgId) {
        try {
          const emails = formData.email?.split('\n')
          const res = await api.invite.inviteOrgMember({
            orgId,
            projectId: formData.project || ''
          }, {
            orgRoleIds: formData.enterpriseRole,
            projectRoleIds: formData.projectRole,
            emails
          })
          if (res && res.code === 200) {
            state.linkUrl = res.data
            state.isInviteModalShow = false
            state.isInviteResultModalShow = true
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    const linkSubmit = async (formData) => {
      const orgId = route.params?.orgId
      if (orgId) {
        try {
          const res = await api.invite.inviteOrgMember({
            orgId,
            projectId: formData.project || ''
          }, {
            orgRoleIds: formData.enterpriseRole,
            projectRoleIds: formData.projectRole
          })
          if (res && res.code === 200) {
            state.linkUrl = res.data
            state.isInviteModalShow = false
            state.isInviteLinkModalShow = true
          }
        } catch (error) {
          console.error(error)
        }
      }
      // console.log(route, api)
      // console.log(formData)
      // state.isInviteResultModalShow = true
    }

    const getTag = (value) => {
      let str, color
      switch (value) {
        case false:
          str = 'enabled'
          color = 'cyan'
          break
        default:
          str = 'disabled'
          color = 'orange'
          break
      }
      return { str, color }
    }

    const getOrgMemberList = async () => {
      const orgId = route.params?.orgId
      if (orgId) {
        try {
          const res = await api.enterprise.getMemberList({
            orgId
          })
          if (res && res.code === 200) {
            state.tableData = res.data?.users
            const roleList = res.data?.orgRoles
            if (roleList) {
              state.tableData = state.tableData.map(i => {
                i.role = getRoleStr(i.orgRoleIds, roleList)
                return i
              })
            }
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    const currentProject = (projectName) => {
      state.projectName = projectName
    }

    const getRoleStr = (value, list) => {
      if (!value) return ''
      const roleList = value.split(',')
      const roles = roleList.map(i => {
        const role = list.find(k => i === k.orgRoleId)
        return role?.orgRoleName || ''
      })
      return roles.toString()
    }

    return {
      ...toRefs(state),
      getTag,
      linkSubmit,
      emailSubmit,
      currentProject
    }
  }
})
</script>
<style lang='less' scoped>
.organization-members-con {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .page-title {
            font-size: 20px;
        }
    }
    .table-filter {
        display: flex;
        max-width: 790px;
        padding: 22px 0;
        .filter-item {
            display: flex;
            white-space: nowrap;
            align-items: center;
            flex: 3;
            margin-left: 40px;
        }
        .filter-title {
            margin-right: 10px;
        }
        .filter-main {
            flex: 1;
        }
    }
    .divide-line {
        border-top: solid rgba(0,0,0,0.1) 1px;
    }
    .page-content {
        flex: 1;
        overflow: auto;
        .action-icon {
            font-size: 18px;
            cursor: pointer;
        }
        .action-icon + .action-icon {
          margin-left: 8px;
        }
        .edit-icon {
            color: #3399FF;
        }
        .delete-icon {
            color: #F31616;
        }
    }
}
</style>
